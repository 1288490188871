<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("treatments") }}</h1>
      <br v-if="false">
      <vx-card v-if="false" title="Filtre" ref="filterCard" actionButtons @refresh="RefrestData()" class="user-list-filters mb-4"  >
      <div class="vx-row ">
        <div class="vx-col w-1/4">
          <p class="mb-2" > Date début </p>
          <flat-pickr 
            :config="configdateTimePicker1" 
            label-placeholder=" Date"
            v-model="start_date"
            name="start_date"
          class="w-full" />
        </div>
        <div class="vx-col w-1/4">
          <p class="mb-2" > Date fin </p>
          <flat-pickr 
          :config="configdateTimePicker2" 
          label-placeholder=" Date"
          v-model="end_date"
          name="end_date"
          class="w-full" />
        </div>

        <div class="vx-col w-1/4">
          <p class="mb-2" > Assureur </p>
          <vs-select
              autocomplete
              v-model="partner"
              class="w-full "
              name="patient"
          >
          <vs-select-item :key="item_" :value="item_.id" :text="item_.name" v-for="item_ in assurances" />
          </vs-select>
        </div>

        <div class="vx-col w-1/4">
          <p class="mb-2" > Patient </p>
          <vs-select
              autocomplete
              v-model="patient"
              class="w-full "
              name="patient"
          >
          <vs-select-item :key="item" :value="item.id" :text="item.last_name" v-for="item in patients" />
          </vs-select>
        </div>
      </div>
      </vx-card>
      <br>
      <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="treatments">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
            </div>

            <div v-if="isFormValid" class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="sendMail">
              <feather-icon icon="MailIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t("Envoyer par mail") }}</span>
            </div>

           <!--  <div v-if="isFormValid" class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="downloadData">
              <feather-icon icon="MailIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t("Téléchager CSV") }}</span>
            </div> -->

            <download-excel
            v-if="isFormValid"
            class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
            :fetch= "downloadData"
            :fields="json_fields"
             work sheet="My Worksheet"
            :name="titleFiche"
          >
            Exporter CSV
          </download-excel>
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ treatments.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : treatments.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
           <!-- ITEMS PER PAGE -->
          <!--<vs-th sort-key="treatments">{{ $t("treatments") }}</vs-th> -->
          <vs-th sort-key="treatments">{{ $t("doctor") }}</vs-th>
          <vs-th sort-key="partnertype">{{ $t("Description") }}</vs-th>
          <vs-th sort-key="partnertype">{{ $t("patient") }}</vs-th>
          <vs-th sort-key="partnertype">{{ $t("actes") }}</vs-th>
          <vs-th sort-key="partnertype">{{ $t("totalFees") }}</vs-th>
          <vs-th sort-key="addDate">{{ $t("treatDate") }}</vs-th>
          <vs-th>Action</vs-th>
        </template>
  
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
             <!-- <vs-td>
              <p class="product-name font-medium truncate">{{tr.name}}</p>
            </vs-td>-->
            <vs-td>
              <p class="product-name font-medium truncate">Dr.{{tr.author.last_name}}{{'  '}}{{tr.author.first_name}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.description}}</p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{tr.patient.last_name}}{{'  '}}{{tr.patient.first_name}}</p>
            </vs-td>
            <vs-td>
                <li v-for="items in tr.actes" :key="items.id" class="product-name font-medium truncate">{{ items.name}}</li>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.totalFees}} FCFA</p>
            </vs-td>
            <vs-td>
              <vs-chip color="primary">
                <vs-avatar icon-pack="feather" icon="icon-edit-2" />
                <p class="product-name font-medium truncate">{{tr.created_at | date_time}}</p>
                </vs-chip>
            </vs-td>


            <vs-td v-if="tr.paid === false" class="whitespace-no-wrap">
              <div class="flex">
                <router-link to="InvoiceTreatements">
                <vx-tooltip text="Voir" color="primary">
                 <feather-icon 
                     style="color: navy " 
                     icon="EyeIcon" 
                     svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      v-on:click="openPlayerDialog(tr)" 
                      color="warring" type="border"
                      class="ml-2" />
                  </vx-tooltip>
                </router-link>
                <vx-tooltip :text="$t('Modifier')" color="warning">
                  <feather-icon
                    style="color: #FF9F43"
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-warning stroke-current"
                    class="ml-2"
                    @click="editData(tr)"
                  />
                </vx-tooltip>
                <vx-tooltip :text="$t('Supprimer')" color="danger">
                  <feather-icon
                    @click="deleteData(tr.id)"
                    style="color: red"
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                  />
                </vx-tooltip>
              </div>
            </vs-td>


            <vs-td v-if="tr.paid === true" class="whitespace-no-wrap">
              <div class="flex">
                <router-link to="/InvoiceTreatements">
                <vx-tooltip text="Voir" color="primary">
                 <feather-icon 
                     style="color: navy " 
                     icon="EyeIcon" 
                     svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      v-on:click="openPlayerDialog(tr)" 
                      color="warring" type="border"
                      class="ml-2" />
                  </vx-tooltip>
                </router-link>
              </div>
            </vs-td>

          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </template>
<script>
  
import DataViewSidebar from './DataViewTreatments.vue'
import modulePrescription from '@/store/prescription/modulePrescription.js'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const French = require('flatpickr/dist/l10n/fr.js').default.fr

export default {
  components: {
    DataViewSidebar,
    flatPickr
  },
  data () {
    return {
      titleFiche:'',
      treatments_download:[],
      assurances:[],
      patients:[],
      partner:'',
      patient:'',
      start_date:'',
      end_date:'',
      configdateTimePicker1: {
        enableTime: 'fasle',
        dateFormat: 'Y-m-d',
        locale: French
      },
      configdateTimePicker2: {
        enableTime: 'fasle',
        dateFormat: 'Y-m-d',
        locale: French
      },
      treatments: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {},
      json_fields: {
        'Nom': 'last_name',
        'Prénom(s)': 'first_name',
        'Age': 'age',
        'Téléphone': 'phoneNumber',
        'Sexe': 'gender',
        'Traitement': 'description',
        'Total des frais': 'totalFees',
        'Date d\'émission':'created_at',
        'Acte(s)':'name_acte',
        'Description de l\'acte':'description_acte',
        'Prix de l\'acte':'price_acte',
        'Taux de couverture':'rate'
      }
    }
  },
  watch:{
    start_date (value) {
      if (value) {
        const start_date = value
        const end_date = this.end_date
        const partner = this.partner
        const patient = this.patient
        this.$vs.loading()
        this.$http.post('bill-by-assurance/', { start_date, end_date, partner, patient   })
          .then((response) => {
            this.treatments = []
            this.treatments = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } 
    },
    end_date (value) {
      if (value) {
        const start_date = this.start_date
        const end_date = value
        const partner = this.partner
        const patient = this.patient
        this.$vs.loading()
        this.$http.post('bill-by-assurance/', { start_date, end_date, partner, patient   })
          .then((response) => {
            this.treatments = []
            this.treatments = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } 
    },
    partner (value) {
      if (value) {
        const start_date = this.start_date
        const end_date = this.end_date
        const partner = value
        const patient = this.patient
        this.$vs.loading()
        this.$http.post('bill-by-assurance/', { start_date, end_date, partner, patient   })
          .then((response) => {
            this.treatments = []
            this.treatments = response.data

            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } 
    },
    patient (value) {
      if (value) {
        const start_date = this.start_date
        const end_date = this.end_date
        const partner = this.partner
        const patient = value
        this.$vs.loading()
        this.$http.post('bill-by-assurance/', { start_date, end_date, partner, patient   })
          .then((response) => {
            this.treatments = []
            this.treatments = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
      } 
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.treatments.length
    },
    isFormValid () {
      return (
        this.start_date !== '' &&
        this.end_date !== '' &&
        this.partner !== '' &&
        this.patient !== '' &&
        this.treatments.length !== 0
      )
    }
  },
  methods: {
    sendMail () {
      const start_date = this.start_date
      const end_date = this.end_date
      const partner = this.partner
      const patient = this.patient
      const send = 'ok'
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation',
        text: 'Voulez-vous envoyé un mail à l\'assureur',
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.post('bill-by-assurance/', { start_date, end_date, partner, patient, send  })
            .then(response => {
              window.getPrendTaCom.success('Mail envoyé', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'Mail non envoyér' })
            })
        }
      })
    },
    downloadData () {
      this.treatments_download = []
      this.titleFiche = `Recouvrement ${this.start_date} au ${this.end_date}.xls`
      let description = ''
      let name = ''
      let price = ''
      let rate_patient = ''
      let sexe = ''
      const results = this.treatments
      results.forEach(event => {
        event.actes.forEach(function (element) {
          description += `${element.description}\n`
          name += `${element.name}\n`
          price += `${element.price} FCFA\n`
        })
        event.patient.patientHasActeHasRate.forEach(function (element_rate) {
          rate_patient += `${element_rate.rate}%\n`
        })
        if (event.patient.gender === 'Male') {
          sexe = 'Masculin'
        } else {
          sexe = 'Féminin'

        }
        this.treatments_download.push({
          description: event.description,
          last_name:event.patient.last_name,
          first_name:event.patient.first_name,
          age:`${event.patient.age} ans`,
          phoneNumber:event.patient.phoneNumber,
          totalFees:`${event.totalFees} FCFA`,
          gender:sexe,
          description_acte:description,
          name_acte:name,
          price_acte:price,
          rate:rate_patient,
          created_at:this.$store.state.convertDateTime(new Date(event.created_at)).date,
          updated_at:this.$store.state.convertDateTime(new Date(event.updated_at)).date
        })
        
      })
      const response = this.treatments_download
      return response
    },
    RefrestData () {
      this.start_date = ''
      this.end_date = ''
      this.partner = ''
      this.patient = ''
      this.$refs.filterCard.removeRefreshAnimation()
      this.getAllTreatments()

    },
    onFromChange (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker2, 'minDate', dateStr)
    },
    onToChange (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker1, 'maxDate', dateStr)
    },
    openPlayerDialog (player) {
      this.$store.dispatch('prescription/addtreatment_invoices', player)

    },
    addNewData () {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
  
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`treatments/${id}/`)
            .then(response => {
              this.getAllTreatments()
              window.getPrendTaCom.success(this.$t('SuppressionTreatment'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })
  
    },
  
    editData (data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
  
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
    getAllTreatments () {
      this.$vs.loading()
      this.$http.get('treatments/')
        .then((response) => {
          this.treatments = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllPatiens () {
      this.$http.get('patients/')
        .then((response) => { this.patients = response.data })
        .catch(() => { 
          this.$vs.loading.close()
        })
    },
    getAllPartner () {
      this.$http.get('assurances/')
        .then((response) => {
          this.assurances = response.data
        })
        .catch(() => {
          this.$vs.loading.close()
        })

    }
  },
  async created () {
    window.treatments = this
    this.getAllTreatments()
    this.getAllPatiens()
    this.getAllPartner()
    this.$store.registerModule('prescription', modulePrescription)
    document.title = 'MooziMed'
  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>


<style scoped>

</style>

<style scoped>

</style>
